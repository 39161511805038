import React, { Component, useState } from 'react'

import LoginPage from './pages/login/Login'
import CameraList from './pages/camera/CameraList'
import Camera from './pages/camera/Camera'
import Shop from './pages/shop/Shop'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import AdManagementRoutes from './pages/routing/AdManagementRoutes'
import CameraContainer from './services/cameraContainer'
import VideoContainer from './services/videoContainerContracts'
import ClubContainer from './services/clubContainer'
import TeamContainer from './services/teamContainer'
import WebRtcContainer from './services/webrtcContainer'
import userContainer from './services/userContainer'
import AdvertisementContainer from './services/advertisementContainer'
import BrowserInfo from './services/browserInfoContainer'
import ContractContainer from './services/contractContainer'
import FieldMaskPage from './pages/fieldmask/FieldMask.page'
import Support from './pages/support/Support'
import Tagging from './pages/tagging/Tagging'
import Administration from './pages/administration/Administration'
import UserManagement from './pages/administration/pages/UserManagement'
import SubscriptionManagement from './pages/administration/pages/SubscriptionManagement'
import AboUpgradePage from './pages/administration/pages/shop/AboUpgrade.page'
import NavBar from './components/NavBar'
import CompetitionContainer from './services/competitionContainer'
import cityContainer from './services/cityContainer'
import ContractForm from './pages/forms/contractForm/contractForm'
import AiswSpinner from './pages/helper/aiswSpinner/aiswSpinner'
import Sidebar from './components/Sidebar'
import LoginWithTokenPage from './pages/login/LoginWithToken'
import AboDowngradePage from './pages/administration/pages/shop/AboDowngrade.page'

import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
  useLocation
} from 'react-router-dom'
import { ExtractRouteParams, Redirect } from 'react-router'
import { Provider, Subscribe } from 'unstated-typescript'
import { ClaimCamera } from './pages/claim/ClaimCamera'
import { RecordingPlanRoutes } from './pages/routing/RecordingPlanRoutes'
import { RouteNames, rerouteOptions, getHighestRelevantRoles } from './pages/menu/Menu'
import { Role } from '@soccerwatch/common'

import './App.scss'

// setup the React Router with Mobiscroll
mobiscroll.setupReactRouter(Route, withRouter)

mobiscroll.settings = {
  theme: 'mobiscroll-dark' /* set global theme */,
  lang: 'de'
}

// config to use react router
;(mobiscroll as typeof mobiscroll & { Route: typeof Route }).Route = Route

function AppContent() {
  const [contractChange, setContractChange] = useState(false)
  const [disableNav, setDisableNav] = useState(false)
  const [disableSidebar, setDisableSidebar] = useState(false)
  const [routeFilter, setRouteFilter] = useState(getHighestRelevantRoles())
  const [checkLogin, setCheckLogin] = useState(true)
  const location = useLocation()

  React.useEffect(() => {
    setRouteFilter(getHighestRelevantRoles())
  }, [contractChange])

  React.useEffect(() => {
    if (location.pathname.includes('/tagging/')) {
      setDisableNav(true)
    } else {
      setDisableNav(false)
    }
  }, [location])

  React.useEffect(() => {
    if (
      location.pathname.includes('/edit/') ||
      location.pathname.includes('/new') ||
      location.pathname.includes('/liveview') ||
      location.pathname.includes('/upgrade') ||
      location.pathname.includes('/downgrade')
    ) {
      setDisableSidebar(true)
    } else {
      setDisableSidebar(false)
    }
  }, [location])

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
  }

  const onContractChange = (e: any, i: any) => {
    const value = i.getVal()
    ContractContainer.setActiveContract(value)
    setContractChange(!contractChange)
  }

  const onContractChangeTest = (id: string) => {
    ContractContainer.setActiveContract(id)
    setContractChange(!contractChange)
  }

  const relevantRole = ContractContainer.getHighestRelevantRoleForCurrentContract()

  const newRouteFilter = (value: Array<string>) => {
    setRouteFilter(value)
  }

  const loginObserver = () => {
    setCheckLogin(true)
  }

  if (checkLogin) {
    userContainer.routeFilterObserver(newRouteFilter)
    setTimeout(() => {
      if (userContainer.state.tryLogin || ContractContainer.state.tryContractData) {
        if (userContainer.state.tryLogin) {
          userContainer.loginObserver(loginObserver)
        }
        setCheckLogin(false)
      }
    }, 500)
  }

  if (checkLogin) {
    return (
      <div style={{ height: '100vh' }}>
        <AiswSpinner />
      </div>
    )
  }

  return (
    <Subscribe
      to={[
        ContractContainer,
        CameraContainer,
        VideoContainer,
        ClubContainer,
        userContainer,
        WebRtcContainer,
        AdvertisementContainer,
        BrowserInfo,
        TeamContainer,
        cityContainer,
        CompetitionContainer
      ]}
    >
      {(
        contractContainer,
        cameraContainer,
        videoContainer,
        clubContainer,
        userContainer,
        webrtcContainer,
        advertisementContainer,
        browserContainer
      ) => (
        <mobiscroll.Page className={`app-page`} key='tes'>
          <div
            className={
              userContainer.state.loggedIn && !disableNav
                ? `page-grid ${disableSidebar ? 'page-grid-dis-sidebar' : ''}`
                : 'page-grid-dis'
            }
          >
            {userContainer.state.loggedIn && !disableNav && !disableSidebar && (
              <Sidebar
                contractContainer={contractContainer}
                clubContainer={clubContainer}
                routeFilter={routeFilter}
                onContractChange={onContractChangeTest}
              />
            )}

            <div className='grid-content'>
              <ContractForm
                open={contractContainer.currentContractNeedsAdditionalData()}
                cityContainer={cityContainer}
                contractContainer={contractContainer}
                clubContainer={clubContainer}
                disableCancelButton
                onClose={async (contractData?: { name: string; clubIds: string[] }) => {
                  const currentContract = contractContainer.getCurrentContract()
                  if (!contractData) {
                    return false
                  }
                  if (!currentContract) {
                    console.error(
                      'Error on Updating ContractData:',
                      contractData,
                      ': Current Contract Undefined'
                    )
                    return false
                  }
                  await contractContainer.postDummyUpdate({ ...contractData, RowKey: currentContract.RowKey })
                  await contractContainer.updateCurrentContract()
                  // * Effectively reloads all Data for this Contract
                  contractContainer.setActiveContract(currentContract.RowKey)
                  return true
                }}
              />
              <Switch>
                <Route
                  path={'/loginwithtoken/cameramonitor/:id/:token'}
                  render={(data) => {
                    if (userContainer.state.loggedIn) {
                      return <Redirect to={'/cameramonitor/' + data.match.params.id} />
                    }
                    return (
                      <LoginWithTokenPage userContainer={userContainer} token={data.match.params.token} />
                    )
                  }}
                />

                <Route
                  path={!userContainer.state.loggedIn ? '/' : '/login'}
                  render={() => {
                    return (
                      <LoginPage
                        loggedIn={userContainer.state.loggedIn}
                        loggedOut={userContainer.handleLogout}
                        userMail={userContainer.state.user?.email}
                        changeLanguage={changeLanguage}
                        userContainer={userContainer}
                      />
                    )
                  }}
                />

                {userContainer.state.loggedIn && (
                  <>
                    <Route
                      path='/'
                      exact
                      render={() => {
                        if (contractContainer.getHighestRelevantRoleForCurrentContract() === Role.adManager) {
                          return (
                            <AdManagementRoutes
                              routeFilter={routeFilter}
                              reroutePath={rerouteOptions[relevantRole]}
                              advertisementContainer={advertisementContainer}
                              contractContainer={contractContainer}
                              cameraContainer={cameraContainer}
                              clubContainer={ClubContainer}
                            />
                          )
                        }
                        return (
                          <RecordingPlanRoutes
                            routeFilter={routeFilter}
                            reroutePath={rerouteOptions[relevantRole]}
                          />
                        )
                      }}
                    />
                    <Route
                      path='/support'
                      render={() => {
                        if (routeFilter && !routeFilter.includes(RouteNames.Support)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return <Support />
                      }}
                    />
                    <Route
                      path='/administration'
                      exact
                      render={() => {
                        if (routeFilter && !routeFilter.includes(RouteNames.Administration)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return <Administration />
                      }}
                    />
                    <Route
                      path='/administration/usermanagement'
                      exact
                      render={() => {
                        if (routeFilter && !routeFilter.includes(RouteNames.Administration)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return (
                          <UserManagement
                            contractContainer={contractContainer}
                            cityContainer={cityContainer}
                            clubContainer={clubContainer}
                            userContainer={userContainer}
                            user={contractContainer.getUserForCurrentContract()}
                          />
                        )
                      }}
                    />

                    <Route
                      path='/administration/subscriptionmanagement'
                      exact
                      render={() => {
                        if (routeFilter && !routeFilter.includes(RouteNames.Administration)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return (
                          <SubscriptionManagement
                            contractContainer={contractContainer}
                            videoContainer={videoContainer}
                            loadingContractData={videoContainer.state.loadingAllContractVideos}
                          />
                        )
                      }}
                    />

                    <Route
                      path='/administration/subscriptionmanagement/upgrade'
                      exact
                      render={() => {
                        if (routeFilter && !routeFilter.includes(RouteNames.Administration)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return <AboUpgradePage />
                      }}
                    />

                    <Route
                      path='/administration/subscriptionmanagement/downgrade'
                      exact
                      render={() => {
                        if (routeFilter && !routeFilter.includes(RouteNames.Administration)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return <AboDowngradePage userContainer={userContainer} />
                      }}
                    />

                    <Route
                      path='/tagging/:videoId'
                      render={(props) => {
                        if (
                          routeFilter &&
                          !routeFilter.includes(RouteNames.Tagging) &&
                          !routeFilter.includes(RouteNames.Trainer)
                        ) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return (
                          <Tagging
                            contractContainer={contractContainer}
                            videoId={props.match.params.videoId}
                            cameraContainer={cameraContainer}
                            videoContainer={videoContainer}
                            clubContainer={clubContainer}
                            camerasLoading={cameraContainer.state.loadingData}
                          />
                        )
                      }}
                    />

                    <Route
                      path='/coachtag/:videoId'
                      render={(props) => {
                        if (routeFilter && !routeFilter.includes(RouteNames.Trainer)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return (
                          <Tagging
                            contractContainer={contractContainer}
                            videoId={props.match.params.videoId}
                            cameraContainer={cameraContainer}
                            videoContainer={videoContainer}
                            clubContainer={clubContainer}
                            camerasLoading={cameraContainer.state.loadingData}
                            backToCoach={true}
                          />
                        )
                      }}
                    />

                    <Route
                      path='/claim/camera/:token'
                      render={(props) => {
                        if (routeFilter && !routeFilter.includes(RouteNames.Claim)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return (
                          <ClaimCamera
                            cameraContainer={cameraContainer}
                            contractContainer={contractContainer}
                            cityContainer={cityContainer}
                            clubContainer={clubContainer}
                            token={props.match.params.token}
                            userContainer={userContainer}
                          />
                        )
                      }}
                    />
                    <Route
                      path='/claim/camera'
                      render={() => {
                        if (routeFilter && !routeFilter.includes(RouteNames.Claim)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return (
                          <ClaimCamera
                            cameraContainer={cameraContainer}
                            contractContainer={contractContainer}
                            cityContainer={cityContainer}
                            clubContainer={clubContainer}
                            userContainer={userContainer}
                          />
                        )
                      }}
                    />
                    <Route
                      path='/cameramonitor'
                      exact
                      render={(props: RouteComponentProps<ExtractRouteParams<string, string>>) => {
                        if (routeFilter && !routeFilter.includes(RouteNames.CameraMonitor)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return (
                          <CameraList
                            {...props}
                            loadingData={
                              cameraContainer.state.loadingData || contractContainer.state.loadingData
                            }
                            cameraContainer={cameraContainer}
                            contractContainer={contractContainer}
                          />
                        )
                      }}
                    />
                    <Route
                      path='/cameramonitor/:id'
                      exact
                      render={(props: RouteComponentProps<ExtractRouteParams<string, string>>) => {
                        if (routeFilter && !routeFilter.includes(RouteNames.CameraMonitor)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        return (
                          <Camera
                            id={parseInt(props.match.params.id ?? '')}
                            cameraContainer={cameraContainer}
                            contractContainer={contractContainer}
                          />
                        )
                      }}
                    />

                    <Route
                      path='/cameramonitor/liveview/new/:id'
                      exact
                      render={(props: RouteComponentProps<ExtractRouteParams<string, string>>) => {
                        if (routeFilter && !routeFilter.includes(RouteNames.CameraMonitor)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        const cameraId = props.match.params.id as string
                        const cam = cameraContainer.state.cameras[cameraId]
                        return cam ? (
                          <FieldMaskPage
                            {...props}
                            cameraId={cam.RowKey}
                            camera={cam}
                            cameraUploading={cam.currentTask === 'uploading'}
                            contractContainer={contractContainer}
                            connectionClosed={webrtcContainer.state.connectionClosedDue}
                            userLoggedIn={userContainer.state.loggedIn}
                            webrtcSrc={webrtcContainer.state.currentStream}
                            webrtcReady={!!webrtcContainer.state.peerId}
                            webrtcContainer={webrtcContainer}
                            // seems to be a constant property in CameraMonitor?!
                            awaitingNewImages={false}
                            sensorSwitchingAt={webrtcContainer.state.sensorSwitchingAt}
                            browserContainer={browserContainer}
                            fieldName={props.match.params.fieldName}
                            isNew
                          />
                        ) : null
                      }}
                    />

                    <Route
                      path='/cameramonitor/liveview/:id/:fieldName'
                      exact
                      render={(props: RouteComponentProps<ExtractRouteParams<string, string>>) => {
                        if (routeFilter && !routeFilter.includes(RouteNames.CameraMonitor)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        const cameraId = props.match.params.id as string
                        const cam = cameraContainer.state.cameras[cameraId]
                        return cam ? (
                          <FieldMaskPage
                            {...props}
                            cameraId={cam.RowKey}
                            camera={cam}
                            cameraUploading={cam.currentTask === 'uploading'}
                            contractContainer={contractContainer}
                            connectionClosed={webrtcContainer.state.connectionClosedDue}
                            userLoggedIn={userContainer.state.loggedIn}
                            webrtcSrc={webrtcContainer.state.currentStream}
                            webrtcReady={!!webrtcContainer.state.peerId}
                            webrtcContainer={webrtcContainer}
                            // seems to be a constant property in CameraMonitor?!
                            awaitingNewImages={false}
                            sensorSwitchingAt={webrtcContainer.state.sensorSwitchingAt}
                            browserContainer={browserContainer}
                            fieldName={props.match.params.fieldName}
                          />
                        ) : null
                      }}
                    />
                    <Route
                      path='/cameramonitor/homeposition/:id/'
                      exact
                      render={(props: RouteComponentProps<ExtractRouteParams<string, string>>) => {
                        if (routeFilter && !routeFilter.includes(RouteNames.CameraMonitor)) {
                          return <Redirect to={rerouteOptions[relevantRole]} />
                        }
                        const cameraId = props.match.params.id as string
                        const cam = cameraContainer.state.cameras[cameraId]
                        return cam ? (
                          <FieldMaskPage
                            {...props}
                            cameraId={cam.RowKey}
                            camera={cam}
                            cameraUploading={cam.currentTask === 'uploading'}
                            contractContainer={contractContainer}
                            connectionClosed={webrtcContainer.state.connectionClosedDue}
                            userLoggedIn={userContainer.state.loggedIn}
                            webrtcSrc={webrtcContainer.state.currentStream}
                            webrtcReady={!!webrtcContainer.state.peerId}
                            webrtcContainer={webrtcContainer}
                            // seems to be a constant property in CameraMonitor?!
                            awaitingNewImages={false}
                            sensorSwitchingAt={webrtcContainer.state.sensorSwitchingAt}
                            browserContainer={browserContainer}
                            homeposition={true}
                          />
                        ) : null
                      }}
                    />
                    <Route path='/advertisement'>
                      <AdManagementRoutes
                        routeFilter={routeFilter}
                        reroutePath={rerouteOptions[relevantRole]}
                        advertisementContainer={advertisementContainer}
                        contractContainer={contractContainer}
                        cameraContainer={cameraContainer}
                        clubContainer={ClubContainer}
                      />
                    </Route>
                    <Route path='/recordingplan'>
                      <RecordingPlanRoutes
                        routeFilter={routeFilter}
                        reroutePath={rerouteOptions[relevantRole]}
                      />
                    </Route>
                    <Route path='/shop' exact render={() => <Shop />} />
                  </>
                )}
              </Switch>
            </div>

            {userContainer.state.loggedIn && !disableNav && !disableSidebar && (
              <NavBar
                onContractChange={onContractChange}
                contractContainer={contractContainer}
                routeFilter={routeFilter}
                clubContainer={clubContainer}
              />
            )}
          </div>
        </mobiscroll.Page>
      )}
    </Subscribe>
  )
}

class App extends Component<unknown, unknown> {
  render() {
    return (
      <Provider>
        <Router>
          <AppContent />
        </Router>
      </Provider>
    )
  }
}

export default App
